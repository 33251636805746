import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import WelcomeBoard from "../images/WelcomeBoard.jpg"
import CD from "../images/TheOffs.jpg"
import Logo69ON from "../images/69on.png"
import sassachikusanHP from "../images/sassachikusanHP.png"

const Product = () => (
    <Layout>
        <Seo title="Product" />
        <div className="main">
            <h1>Product</h1>
            <section className="contents">
                <h2>Welcome Board</h2>
                <img className="width_30vw margin-top_10vh margin-bottom_10vh" src={WelcomeBoard} alt="ウェルカムボード"/>
                <h3>結婚式のウェルカムボード</h3>
                <p className="discription">
                    結婚式に使われるウェルカムボードです。デザインから作成までさせていただきました。
                </p>

                {/* <Link to="//" className="button">詳しく見る</Link> */}
            </section>

            <section className="contents">
                <h2>CD</h2>
                <img className="width_30vw margin-top_10vh margin-bottom_10vh" src={CD} alt="CD"/>
                <h3>アメリカのパンクバンド「The Offs」のCD</h3>
                <p className="discription">
                    ジャケット、歌詞カード、盤面のデザインを担当しました。
                </p>

                {/* <Link to="//" className="button">詳しく見る</Link> */}
            </section>

            <section className="contents">
                <h2>Logo/Tshirts</h2>
                <img className="width_30vw margin-top_10vh margin-bottom_10vh" src={Logo69ON} alt="ロックオン"/>
                <h3>ハードロックバンド「69ON」のロゴ</h3>
                <p className="discription">
                    ロゴの作成と、それを使ったTシャツを作成しました。
                </p>

                {/* <Link to="//" className="button">詳しく見る</Link> */}
            </section>

            <section className="contents">
                <h2>Web development</h2>
                <img className="width_30vw margin-top_10vh margin-bottom_10vh" src={sassachikusanHP} alt="佐々畜産HP"/>
                <h3>株式会社佐々畜産様HP</h3>
                <p className="discription">
                    デザイン、画像処理、コーディングを行いました。
                </p>

                {/* <Link to="//" className="button">詳しく見る</Link> */}
            </section>
        </div>
    </Layout>
  )
  
  export default Product